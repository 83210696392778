/* eslint-disable @typescript-eslint/no-unused-vars */
import { IContaBancaria } from "@/types/IContaBancaria";
import { IEmpresa } from "@/types/IEmpresa";
import { defineStore } from "pinia";
import { buscarContasBancarias } from "@/requests/Financeiro/ContasBancarias";
import { useUser } from "./auth";
import { getUsersInFirebase } from "@/helpers/firebase";

export interface IEmpresaState {
  currentEmpresa: IEmpresa;
  contasBancarias: IContaBancaria[];
  dashboardPrincipal: any;
  firebaseUsers: any[];
}

export const useEmpresa = defineStore("empresa", {
  state: (): IEmpresaState => ({
    currentEmpresa: [] as unknown as IEmpresa,
    contasBancarias: [] as unknown as IContaBancaria[],
    dashboardPrincipal: {},
    firebaseUsers: [],
  }),
  getters: {
    async getContasBancarias(): Promise<IContaBancaria[]> {
      return this.contasBancarias;
    },
    getDashboardPrincipal(): any {
      return this.dashboardPrincipal;
    },
  },
  actions: {
    async getCurrentEmpresa(): Promise<IEmpresa> {
      await useUser().getCurrentUser();
      return this.currentEmpresa;
    },
    setCurrentEmpresa(empresa: IEmpresa): IEmpresa {
      this.currentEmpresa = empresa;
      return empresa;
    },
    async getUsersFirebase(): Promise<any[]> {
      if (this.firebaseUsers.length === 0) {
        const users = await getUsersInFirebase(this.currentEmpresa.id);
        this.firebaseUsers = users;
        return this.firebaseUsers;
      } else {
        return this.firebaseUsers;
      }
    },
  },
});
