export default {
  mounted() {
    if (typeof this.$configureMetaTags === "function" && this.title) {
      this.$configureMetaTags(this);
    } else {
      console.warn(
        "A função $configureMetaTags não está definida globalmente."
      );
    }
  },
};
