import { db } from "@/main";
import {
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
  addDoc,
  query,
  where,
  orderBy,
  limit,
  serverTimestamp,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { debug } from "./debugPlugin";
import { IPayment } from "@/types/IPayment";

export async function fetchAllDocsAndSubCollections(
  collectionName: string,
  empresaId: number // Adicionado como parâmetro para o filtro
): Promise<any[]> {
  async function fetchDocs(
    collectionName: string,
    empresaId: number
  ): Promise<any[]> {
    const docResults = [];
    const targetCollection = collection(db, collectionName);

    // Criar consulta filtrando por empresaId
    const filteredQuery = query(
      targetCollection,
      where("empresaId", "==", empresaId)
    );

    // Obter todos os documentos que atendem ao filtro
    const docsSnapshot = await getDocs(filteredQuery);

    for (const docSnap of docsSnapshot.docs) {
      const docData = docSnap.data();
      docData.id = docSnap.id;

      // Verificar se o documento possui subcoleções listadas
      if (docData.subCollections && Array.isArray(docData.subCollections)) {
        // Iterar sobre as subcoleções e buscar os documentos dentro delas
        for (const subCollectionName of docData.subCollections) {
          const subCollectionRef = collection(
            db,
            collectionName,
            docSnap.id,
            subCollectionName
          );
          const subCollectionSnapshot = await getDocs(subCollectionRef);

          docData[subCollectionName] = subCollectionSnapshot.docs.map(
            (subDocSnap) => ({
              id: subDocSnap.id,
              ...subDocSnap.data(),
            })
          );
        }
      }

      docResults.push(docData);
    }

    return docResults;
  }

  return await fetchDocs(collectionName, empresaId);
}

export async function fetchDocsAndSubcollectionsById(
  collectionName: string,
  id: string // ID do documento principal
): Promise<any> {
  try {
    const results = [];
    const targetDocRef = doc(collection(db, collectionName), id);

    // Obter o documento principal com base no ID fornecido
    const docSnap = await getDoc(targetDocRef);

    if (!docSnap.exists()) {
      throw new Error(
        `Documento com ID ${id} não encontrado na coleção ${collectionName}`
      );
    }

    const docData = docSnap.data();
    docData.id = docSnap.id;

    // Verificar se há subcoleções
    if (docData.subCollections && Array.isArray(docData.subCollections)) {
      for (const subCollectionName of docData.subCollections) {
        const subCollectionRef = collection(
          db,
          collectionName,
          id,
          subCollectionName
        );
        const subCollectionSnapshot = await getDocs(subCollectionRef);

        // Adicionar os documentos das subcoleções
        docData[subCollectionName] = subCollectionSnapshot.docs.map(
          (subDocSnap) => ({
            id: subDocSnap.id,
            ...subDocSnap.data(),
          })
        );
      }
    }

    results.push(docData);

    return results;
  } catch (error) {
    console.error("Erro ao buscar documentos e subcoleções:", error);
    throw error;
  }
}

export async function fetchDocsAndSubcollectionsByParentAndChildId(
  parentCollection: string,
  parentId: string,
  childCollection: string,
  childId: string
): Promise<any> {
  try {
    const targetDocRef = doc(
      db,
      parentCollection,
      parentId,
      childCollection,
      childId
    );

    // Obter o documento específico dentro da subcoleção
    const docSnap = await getDoc(targetDocRef);

    if (!docSnap.exists()) {
      throw new Error(
        `Documento com ID ${childId} não encontrado em ${parentCollection}/${parentId}/${childCollection}`
      );
    }

    const docData = { id: docSnap.id, ...docSnap.data() };

    // Função recursiva para buscar subcoleções
    // eslint-disable-next-line no-inner-declarations
    async function fetchSubcollections(
      currentDocPath: string[],
      currentDocData: any
    ) {
      if (
        currentDocData.subCollections &&
        Array.isArray(currentDocData.subCollections)
      ) {
        for (const subCollectionName of currentDocData.subCollections) {
          const subCollectionRef = (collection as any)(
            db,
            ...currentDocPath,
            subCollectionName
          );
          const subCollectionSnapshot = await getDocs(subCollectionRef);

          currentDocData[subCollectionName] = [];

          for (const subDocSnap of subCollectionSnapshot.docs) {
            const subDocData = {
              id: subDocSnap.id,
              ...(subDocSnap.data() as any),
            };
            currentDocData[subCollectionName].push(subDocData);

            // Repetir para as subcoleções do documento atual
            const subDocPath = [
              ...currentDocPath,
              subCollectionName,
              subDocSnap.id,
            ];
            await fetchSubcollections(subDocPath, subDocData);
          }
        }
      }
    }

    // Caminho inicial
    const initialPath = [parentCollection, parentId, childCollection, childId];

    // Iniciar a busca recursiva de subcoleções
    await fetchSubcollections(initialPath, docData);

    return docData;
  } catch (error) {
    console.error(
      "Erro ao buscar documentos e subcoleções recursivamente:",
      error
    );
    throw error;
  }
}

/**
 * Função para deletar um documento no Firestore com base em um caminho completo.
 *
 * @param path - Caminho completo até o documento, como uma lista de segmentos.
 *               Exemplo: ['collection', 'id', 'subcollection', 'sub-id']
 * @returns void
 */
export async function deleteDocumentInFirebase(path: string[]): Promise<void> {
  try {
    if (path.length < 2) {
      throw new Error("O caminho deve incluir pelo menos uma coleção e um ID.");
    }
    const docRef = doc(db as any, ...path);
    await deleteDoc(docRef);

    debug(`Deletou um documento Firebase: ${path.join("/")}`);
  } catch (error) {
    console.error("Erro ao deletar o documento:", error);
    throw error;
  }
}
export async function deleteSubdocumentInFirebase(
  fatherCollection: string,
  fatherId: string,
  childCollection: string,
  childId: string
): Promise<void> {
  try {
    // Referência para o subdocumento na subcoleção dentro do documento pai
    const childRef = doc(
      db,
      fatherCollection,
      fatherId,
      childCollection,
      childId
    );

    // Deleta o subdocumento
    await deleteDoc(childRef);
    debug(
      `Deletou um documento Firebase: ${fatherCollection}/${fatherId}/${childCollection}/${childId}`
    );
    return;
  } catch (error) {
    console.error("Erro ao deletar o subdocumento:", error);
    throw error;
  }
}
export async function createSubdocumentFirebase(
  fatherCollection: string,
  fatherId: string,
  childCollection: string,
  data: any,
  returnData: boolean,
  customChildId?: string
): Promise<any> {
  try {
    let childRef;

    if (customChildId) {
      // Caso um ID seja fornecido, cria a referência com o ID
      childRef = doc(
        db,
        fatherCollection,
        fatherId,
        childCollection,
        customChildId
      );
      await setDoc(childRef, data, { merge: true });
    } else {
      // Caso contrário, cria um documento com ID automático
      const childCollectionRef = collection(
        db,
        fatherCollection,
        fatherId,
        childCollection
      );
      childRef = await addDoc(childCollectionRef, data);
    }

    debug(
      `Criou um documento Firebase: ${fatherCollection}/${fatherId}/${childCollection}/${
        customChildId || childRef.id
      }`
    );

    if (!returnData) {
      return;
    }

    // Retorna os dados do documento criado
    const createdChildSnap = await getDoc(childRef);
    if (createdChildSnap.exists()) {
      debug("Buscou e retornou dados do doc: ", createdChildSnap.data());
      return {
        id: createdChildSnap.id,
        uid: createdChildSnap.id,
        ...createdChildSnap.data(),
      };
    } else {
      throw new Error("Falha ao recuperar o subdocumento recém-criado.");
    }
  } catch (error) {
    console.error("Erro ao criar o subdocumento:", error);
    throw error;
  }
}

/**
 * Função para criar um documento no Firestore em uma coleção ou em um caminho completo.
 *
 * @param path - Caminho completo até a coleção (como uma lista de segmentos).
 * @param data - Dados do documento a ser criado.
 * @param returnData - Se `true`, retorna os dados do documento criado.
 * @returns Dados do documento criado, se `returnData` for `true`.
 */
export async function createDocumentFirebase(
  path: string[], // Caminho completo como uma lista de segmentos
  data: any, // Dados do documento
  returnData?: boolean // Se `true`, retorna os dados do documento criado
): Promise<any> {
  try {
    const collectionRef = (collection as any)(db, ...path);

    // Adiciona um novo documento à coleção (ID gerado automaticamente)
    const docRef = await addDoc(collectionRef, data);
    debug(`Criou um documento Firebase: ${path.join("/")}/${docRef.id}`);

    // Retorna os dados do documento criado, se solicitado
    if (!returnData) {
      return;
    } else {
      const createdDocSnap = await getDoc(docRef);
      if (createdDocSnap.exists()) {
        debug("Buscou e retornou dados do doc: ", createdDocSnap.data());
        return {
          id: createdDocSnap.id,
          uid: createdDocSnap.id,
          ...createdDocSnap.data(),
        }; // Retorna o ID e os dados do documento
      } else {
        throw new Error("Falha ao recuperar o documento recém-criado.");
      }
    }
  } catch (error) {
    console.error("Erro ao criar o documento:", error);
    throw error;
  }
}

/**
 * Nessa função você edita o documento diretamente no Firebase e retorna ela atualizada
 *
 * @param collectionName
 * @param docId
 * @param updatedData
 * @returns updated doc
 */
export async function editDocumentInFirebase(
  collectionName: string,
  docId: string,
  updatedData: Record<string, any>
): Promise<any> {
  try {
    // Referência para o documento no Firestore
    const docRef = doc(db, collectionName, docId);

    // Atualiza o documento com os novos dados
    await updateDoc(docRef, updatedData);

    // Obtém o documento atualizado
    const updatedDoc = await getDoc(docRef);

    // Verifica se o documento existe e retorna os dados atualizados
    if (updatedDoc.exists()) {
      debug(`Documento atualizado: ${collectionName}/${docId}`);
      return { id: updatedDoc.id, uid: updatedDoc.id, ...updatedDoc.data() };
    } else {
      throw new Error("Documento não encontrado após a atualização.");
    }
  } catch (error) {
    console.error("Erro ao atualizar o documento:", error);
    throw error;
  }
}
/**
 * Função genérica para editar documentos em qualquer caminho no Firestore.
 *
 * @param path - Caminho completo até o documento como uma lista de segmentos.
 * @param updatedData - Dados que serão atualizados no documento.
 */
export async function editDocumentAtPath(
  path: string[], // Caminho como lista de strings
  updatedData: any // Dados a serem atualizados
): Promise<void> {
  try {
    // Obter a referência do documento com o caminho fornecido
    const docRef = doc(db as any, ...path);

    // Obter o documento para verificar sua existência
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      throw new Error(`Documento não encontrado no caminho ${path.join("/")}`);
    }

    // Atualizar o documento com os dados fornecidos
    await updateDoc(docRef, updatedData);

    debug(`Documento no caminho ${path.join("/")} atualizado com sucesso`);
  } catch (error) {
    console.error("Erro ao editar documento no Firestore:", error);
    throw error;
  }
}
export async function uploadImage(
  file: File | Blob,
  folder: string
): Promise<string> {
  try {
    const storage = getStorage();
    const fileName = `${folder}/${Date.now()}_${file.name}`;
    const fileRef = ref(storage, fileName);

    // Faz upload da imagem
    const snapshot = await uploadBytes(fileRef, file);
    debug(`Imagem enviada com sucesso: ${snapshot.metadata.fullPath}`);

    // Obtém a URL de download público
    const downloadURL = await getDownloadURL(fileRef);
    debug(`URL da imagem: ${downloadURL}`);

    return downloadURL;
  } catch (error) {
    console.error("Erro ao enviar imagem:", error);
    throw error;
  }
}

export async function getMoneyCashBox(
  empresaId: number // Adicionado como parâmetro para o filtro
): Promise<any> {
  async function fetchDocs(empresaId: number): Promise<any[]> {
    const docResults = [];
    const targetCollection = collection(db, "cashs-box");

    let docsSnapshot;
    const filteredQuery = query(
      targetCollection,
      where("empresaId", "==", empresaId),
      where("isOpen", "==", true),
      orderBy("createdAt", "desc"),
      limit(1)
    );
    docsSnapshot = await getDocs(filteredQuery);
    if (docsSnapshot.size == 0) {
      const filteredQuery = query(
        targetCollection,
        where("empresaId", "==", empresaId),
        where("isOpen", "==", false),
        where("userId", "==", "0"),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      // Documents where cash isOpen
      docsSnapshot = await getDocs(filteredQuery);
    }

    if (docsSnapshot.size > 0) {
      for (const docSnap of docsSnapshot.docs) {
        const docData = docSnap.data();
        docData.id = docSnap.id;

        // Verificar se o documento possui subcoleções listadas
        if (docData.subCollections && Array.isArray(docData.subCollections)) {
          // Iterar sobre as subcoleções e buscar os documentos dentro delas
          for (const subCollectionName of docData.subCollections) {
            const subCollectionRef = collection(
              db,
              "cashs-box",
              docSnap.id,
              subCollectionName
            );
            const subCollectionSnapshot = await getDocs(subCollectionRef);

            docData[subCollectionName] = subCollectionSnapshot.docs.map(
              (subDocSnap) => ({
                id: subDocSnap.id,
                ...subDocSnap.data(),
              })
            );
          }
        }

        docResults.push(docData);
      }
    } else {
      // Referência para a coleção principal
      const collectionRef = collection(db, "cashs-box");

      // Adiciona um novo documento à coleção (ID gerado automaticamente)
      const docRef = await addDoc(collectionRef, {
        empresaId,
        userId: "0",
        userIdFinish: "0",
        totalInvoices: null,
        totalMoney: null,
        totalMoneyEfetued: null,
        totalCreditCard: null,
        totalCreditCardEfetued: null,
        totalDebitCard: null,
        totalDebitCardEfetued: null,
        totalPix: null,
        totalPixEfetued: null,
        totalCheck: null,
        totalCheckEfetued: null,
        totalWithdraw: null,
        totalSangria: 0,
        initialValue: 0,
        contaBancariaId: null,
        notes: "",
        isOpen: false,
        subCollections: ["invoices", "money-transfers", "differences"],
        createdAt: serverTimestamp(),
        finishedAt: null,
      });
      debug(`Criou um documento Firebase: cashs-box/${docRef.id}`);
      const createdDocSnap = await getDoc(docRef);

      if (createdDocSnap.exists()) {
        debug("Buscou e retornou dados do doc: ", createdDocSnap.data());
        docResults.push({
          id: createdDocSnap.id,
          uid: createdDocSnap.id,
          ...createdDocSnap.data(),
        });
      }
    }

    return docResults;
  }

  return (await fetchDocs(empresaId))[0];
}

export async function getBasicMoneyCashBox(
  empresaId: number // Adicionado como parâmetro para o filtro
): Promise<any> {
  async function fetchDocs(empresaId: number): Promise<any> {
    let docResults = null;
    const targetCollection = collection(db, "cashs-box");

    const filteredQuery = query(
      targetCollection,
      where("empresaId", "==", empresaId),
      where("isOpen", "==", true),
      orderBy("createdAt", "desc"),
      limit(1)
    );
    const docsSnapshot = await getDocs(filteredQuery);

    if (docsSnapshot.empty) return docResults;
    docResults = docsSnapshot.docs[0].data();

    return {
      ...docResults,
      id: docsSnapshot.docs[0].id,
    };
  }

  return await fetchDocs(empresaId);
}
export async function createInvoiceWithDetails(
  cashBoxId: string, // ID do caixa principal
  invoiceData: any, // Dados da fatura (invoice)
  orders: Array<{ data: any; items: Array<any> }>, // Lista de pedidos e seus itens
  payments: Array<IPayment>, // Lista de pagamentos
  customInvoiceId?: string // ID customizado para a invoice
): Promise<any> {
  try {
    let invoiceRef;

    // Criar a invoice com ID automático ou customizado
    if (customInvoiceId) {
      invoiceRef = doc(
        collection(db, "cashs-box", cashBoxId, "invoices"),
        customInvoiceId
      );
      await setDoc(invoiceRef, invoiceData, { merge: true });
    } else {
      const invoiceCollectionRef = collection(
        db,
        "cashs-box",
        cashBoxId,
        "invoices"
      );
      invoiceRef = await addDoc(invoiceCollectionRef, invoiceData);
    }

    const invoiceId = invoiceRef.id;

    // Adicionar orders e seus itens à invoice
    for (const order of orders) {
      const orderRef = await addDoc(
        collection(db, "cashs-box", cashBoxId, "invoices", invoiceId, "orders"),
        order.data
      );

      // Adicionar itens à order
      for (const item of order.items) {
        await addDoc(
          collection(
            db,
            "cashs-box",
            cashBoxId,
            "invoices",
            invoiceId,
            "orders",
            orderRef.id,
            "items"
          ),
          item
        );
      }
    }

    // Adicionar pagamentos à invoice
    for (const payment of payments) {
      await addDoc(
        collection(
          db,
          "cashs-box",
          cashBoxId,
          "invoices",
          invoiceId,
          "payments"
        ),
        payment
      );
    }

    debug(
      `Invoice criada com sucesso: cashs-box/${cashBoxId}/invoices/${invoiceId}`
    );

    return await fetchDocsAndSubcollectionsByParentAndChildId(
      "cashs-box",
      cashBoxId,
      "invoices",
      invoiceId
    );
  } catch (error) {
    console.error("Erro ao criar a invoice e detalhes:", error);
    throw error;
  }
}

export async function getUsersInFirebase(empresaId: number): Promise<any> {
  try {
    const targetCollection = collection(db, "users");

    const filteredQuery = query(
      targetCollection,
      where("empresaId", "==", empresaId)
    );
    const docsSnapshot = await getDocs(filteredQuery);
    if (docsSnapshot.empty) {
      return [];
    } else {
      return docsSnapshot.docs.map((i) => i.data());
    }
  } catch (error) {
    //nothing
  }
}

export function generateUniqueCustomID() {
  const now = Date.now(); // Obtém o timestamp atual em milissegundos
  const randomFactor = Math.floor(Math.random() * 100); // Adiciona um fator aleatório
  const uniqueCode = (now + randomFactor) % 10000; // Garante que o resultado tenha no máximo 4 dígitos
  return uniqueCode.toString().padStart(4, "0"); // Retorna com 4 dígitos, adicionando zeros à esquerda se necessário
}

export async function getDebitsByCreatedAt(
  startDate: any,
  endDate: any,
  empresaId: number
) {
  try {
    const debitsCollection = collection(db, "debits");

    // Cria a query filtrando por 'createdAt'
    const debitsQuery = query(
      debitsCollection,
      where("createdAt", ">=", startDate),
      where("createdAt", "<=", endDate),
      where("empresaId", "==", empresaId)
    );

    // Executa a query e obtém os documentos
    const querySnapshot = await getDocs(debitsQuery);

    // Mapeia os documentos retornados
    const results = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return results;
  } catch (error) {
    console.error("Erro ao buscar registros:", error);
    throw error; // Lança o erro para tratamento externo
  }
}

export async function getCanceledByCreatedAt(
  startDate: any,
  endDate: any,
  empresaId: number
) {
  try {
    const debitsCollection = collection(db, "canceled-invoices");

    // Cria a query filtrando por 'createdAt'
    const debitsQuery = query(
      debitsCollection,
      where("createdAt", ">=", startDate),
      where("createdAt", "<=", endDate),
      where("empresaId", "==", empresaId)
    );

    // Executa a query e obtém os documentos
    const querySnapshot = await getDocs(debitsQuery);

    // Mapeia os documentos retornados
    const results = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return results;
  } catch (error) {
    console.error("Erro ao buscar registros:", error);
    throw error; // Lança o erro para tratamento externo
  }
}

export async function getUsedCouponsByCreatedAt(
  startDate: any,
  endDate: any,
  empresaId: number
) {
  try {
    const debitsCollection = collection(db, "coupons-used");

    // Cria a query filtrando por 'createdAt'
    const debitsQuery = query(
      debitsCollection,
      where("createdAt", ">=", startDate),
      where("createdAt", "<=", endDate),
      where("empresaId", "==", empresaId)
    );

    // Executa a query e obtém os documentos
    const querySnapshot = await getDocs(debitsQuery);

    // Mapeia os documentos retornados
    const results = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return results;
  } catch (error) {
    console.error("Erro ao buscar registros:", error);
    throw error; // Lança o erro para tratamento externo
  }
}

export async function getAllCoupons(empresaId: number) {
  try {
    const debitsCollection = collection(db, "coupons");

    // Cria a query filtrando por 'createdAt'
    const debitsQuery = query(
      debitsCollection,
      where("empresaId", "==", empresaId)
    );

    // Executa a query e obtém os documentos
    const querySnapshot = await getDocs(debitsQuery);

    // Mapeia os documentos retornados
    const results = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return results;
  } catch (error) {
    console.error("Erro ao buscar cupons:", error);
    throw error;
  }
}

export async function getCouponByCode(
  empresaId: number,
  code: string
): Promise<any[]> {
  async function fetchDocs(
    collectionName: string,
    empresaId: number
  ): Promise<any[]> {
    const docResults = [];
    const targetCollection = collection(db, collectionName);

    const filteredQuery = query(
      targetCollection,
      where("empresaId", "==", empresaId),
      where("cod", "==", code)
    );

    const docsSnapshot = await getDocs(filteredQuery);

    for (const docSnap of docsSnapshot.docs) {
      const docData = docSnap.data();
      docData.id = docSnap.id;

      // Verificar se o documento possui subcoleções listadas
      if (docData.subCollections && Array.isArray(docData.subCollections)) {
        // Iterar sobre as subcoleções e buscar os documentos dentro delas
        for (const subCollectionName of docData.subCollections) {
          const subCollectionRef = collection(
            db,
            collectionName,
            docSnap.id,
            subCollectionName
          );
          const subCollectionSnapshot = await getDocs(subCollectionRef);

          docData[subCollectionName] = subCollectionSnapshot.docs.map(
            (subDocSnap) => ({
              id: subDocSnap.id,
              ...subDocSnap.data(),
            })
          );
        }
      }

      docResults.push(docData);
    }

    return docResults;
  }

  return await fetchDocs("coupons", empresaId);
}
